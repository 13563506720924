<template>
  <div class="solutions px-4  container mx-auto">
    <div
      v-for="solution in solutions"
      :key="solution.slug"
      v-scroll-reveal
      class="solution items-center flex-col md:flex-row"
    >
      <img
        v-scroll-reveal
        :src="'/img/' + solution.slug + solution.image_extension"
        :alt="solution.name[lang] + ' image'"
        class="self-center"
        loading="lazy"
      />
      <div v-scroll-reveal>
        <p v-if="home" class="uppercase font-light -mb-1">
          / {{ $t('OUR_SOLUTIONS') }}
        </p>
        <h2 class="text-3xl text-black font-bold mt-0 mb-4">
          {{ solution.name[lang] }} 
        </h2>
        <p class="text-gray-900 text-light mb-12 text-sm">
          {{ solution.description[lang] }}
        </p>
        <nuxt-link
          :to="
            localePath({
              name: 'solution-slug',
              params: { slug: solution.slug }
            })
          "
          class="bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white mt-6 text-white px-5 py-3"
          >{{ $t('read_more') }}
          <!-- about {{ solution.name[lang] }} -->
          </nuxt-link
        >
      </div>
    </div>
    <div class="solution items-center flex-col md:flex-row">
      <img
        v-scroll-reveal
        :src="'/img/' + 'automotive-logistics' + '.webp'"
        alt="automotive-logistics image"
        class="self-center"
      />

      <div v-scroll-reveal>
        <p v-if="home" class="uppercase font-light -mb-1">
          / {{ $t('OUR_SOLUTIONS') }}
        </p>
        <h2 class="text-3xl text-black font-bold mt-0 mb-4">
          {{ $t('automotive_logistics') }} 
        </h2>
        <p class="text-gray-900 text-light mb-12 text-sm">
          {{ $t('automotive_logistics_description') }}
        </p>
        <nuxt-link
          :to="
            localePath({
              name: 'solution-slug',
              params: { slug: 'automotive-logistics' }
            })
          "
          class="bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white mt-6 text-white px-5 py-3"
          >{{ $t('read_more') }}
          {{ $t('automotive_logistics') }}
          </nuxt-link
        >
      </div>
      
    </div>
    
  </div>
</template>

<script>
import solutions from '~/static/solutions.json'

export default {
  props: {
    home: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      solutions
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    languagePrefix() {
      return this.$i18n.locale !== 'en' ? this.$i18n.locale + '/' : ''
    }
  }
}
</script>

<style scoped>
.solution {
  display: flex;
  margin-bottom: 35px;
  min-height: 300px;
  /* max-height: 350px; */
}
img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.text-gray-900 {
  @apply text-justify;
}

@screen md {
  .solution:nth-child(even) {
    flex-direction: row-reverse;
  }
  .solution img {
    width: 50%;
    height: auto;
    height: 451px;
    object-fit: cover;
  }
  .solution div {
    padding-right: 25px;
    padding-left: 25px;
    width: 50%;
  }
}
</style>
