<template>
  <div>
    <!-- <nuxt-link :to="$t('link_to')" class="heroimg">
      <img :src="$t('hero_img')" class="self-center " />
    </nuxt-link> -->
    <template>
      <div v-swiper:mySwiper="swiperOption" class="swiper-container">
        <div class="swiper-wrapper">
          <!-- slides -->
          <div class="swiper-slide">
            <nuxt-link
              :to="
                localePath({
                  name: 'about',
                  params: { slug: '' }
                })
              "
              class="heroimg"
            >
              <img
                :src="$t('hero_img2')"
                alt="hero image"
                title="hero image"
                class="self-center "
              />
            </nuxt-link>
          </div>
          <div class="swiper-slide">
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-contract-logistics-slug',
                  params: { slug: 'automated-sortation-system' }
                })
              "
              class="heroimg"
            >
              <img
                :src="$t('hero_img3')"
                alt="hero image"
                title="hero image"
                class="self-center "
              />
            </nuxt-link>
          </div>

          <div class="swiper-slide"> 
            <nuxt-link
              :to="
                localePath({
                  name: 'solution-contract-logistics-slug',
                  params: { slug: 'autostore' }
                })
              "
              class="heroimg"
            >
              <img
                :src="$t('hero_img')"
                alt="hero image"
                class="self-center "
              />
            </nuxt-link>
          </div>
          <div class="swiper-slide"> 
            <nuxt-link
              :to="
                localePath({
                  name: 'career-programletnjeprakse-stipendiranjestudenata',
                  params: { slug: '' }
                })
              "
              class="heroimg"
            >
              <img
                :src="$t('hero_img4')"
                alt="hero image"
                class="self-center "
              />
            </nuxt-link>
          </div>
        </div>
        <!-- Optional controls -->
        <div slot="pagination" class="mt-24 swiper-pagination"></div>
        <div
          slot="button-prev"
          class="hidden sm:block swiper-button-prev"
        ></div>
        <div
          slot="button-next"
          class="hidden sm:block swiper-button-next"
        ></div>
      </div>
    </template>
    <!-- <div
        :style="
          `background-image: url(${$t(
            'hero_img'
          )}); background-size: 100% ; background-position: center; background-repeat: no-repeat;`
        "
        class="hero flex items-center"
      >
        <div
          v-parallax="0.15"
          class="hero-content px-4 items-center container mx-auto"
        >
          <no-ssr> 
           <vue-typer
            :text="$t('SLOGAN')"
            :repeat="0"
            :pre-type-delay="1000"
            :type-delay="150"
            class="h-12 text-4xl md:text-5xl block font-bold text-white leading-tight mb-6"
          ></vue-typer> 
          </no-ssr>

         <nuxt-link
          :to="localePath('about')"
          class="bg-transparent hover:bg-blue:400 text-white uppercase border-2 border-white px-8 py-2 hover:bg-white hover:text-black"
          >{{ $t('about_us') }}</nuxt-link
        > 
        </div>
      </div> -->

    <BrowseNetwork />
    <TrackerBanner />
    <Stats v-scroll-reveal />
    <News />
    <Solutions :home="true" />
    <section
      class="quote text-white text-center flex flex-col items-center justify-center px-4"
    >
      <div class="container-sm mx-auto md:px-64">
        <p class="text-2xl mb-8">{{ $t('home_banner_text') }}</p>
        <p>{{ $t('home_banner_footer') }}</p>
      </div>
    </section>
    <SvetLogistike v-scroll-reveal />
  </div>
</template>

<script>
import TrackerBanner from '@/components/TrackerBanner.vue'
import Stats from '~/components/Stats'
import Solutions from '~/components/Solutions'
import SvetLogistike from '~/components/SvetLogistike'
import BrowseNetwork from '~/components/BrowseNetwork'
import News from '~/components/News'

export default {
  components: {
    Stats,
    Solutions,
    SvetLogistike,
    BrowseNetwork,
    News,
    TrackerBanner
  },
  data() {
    return {
      loaded: false,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        autoHeight: true,
        spaceBetween: 10,
        loop: true,
        autoplay: {
          delay: 7500, // Vreme zadržavanja između slajdova (u milisekundama)
          disableOnInteraction: false // Omogućava autoplay i nakon interakcije korisnika (klik, prevlačenje)
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        allowTouchMove: false,
        slidesPerGroup: 1,
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          }
        }
      }
    }
  },
  mounted() {
    this.loaded = true
  }
}
</script>

<style>
/* Sample `apply` at-rules with Tailwind CSS
.container {
  @apply min-h-screen flex justify-center items-center text-center mx-auto;
}
*/
.hero {
  /* background: url('/img/autostore-sr.jpg'); */
  background-position: center;
  background-size: cover;
  min-height: 60vh;
  position: relative;
}

.heroimg {
  position: relative;
}
.heroimg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgba(8, 98, 241, 0.61),
    rgba(0, 0, 0, 0)
  );
}
.hero-content {
  z-index: 20;
}
a {
  transition: 0.2s;
}

.quote {
  min-height: 50vh;
  background: url('/img/quote.webp');
}
@media (max-width: 768px) {
  .hero {
    min-height: 25vh;
  }
}
</style>
