<template class="bg-gray-300">
  <no-ssr>
    <div class="bg-gray-300 py-8">
      <div class="container px-4 mx-auto">
        <div v-swiper:mySwiper="swiperOption" class="swiper-container">
          <div class="swiper-wrapper py-16">
            <!-- slides -->
            <div
              v-for="(post, index) in posts.slice(0, 7)"
              :key="index"
              class="swiper-slide"
            >
              <div class="mx-1">
                <div
                  class="post-container flex flex-col lg:flex-row bg-white shadow-md"
                >
                  <div class="img-wrapper">
                    <img
                      :src="
                        '/img/news/' +
                          (Array.isArray(post.img) ? post.img[0] : post.img)
                      "
                      :alt="post.name"
                      class="post-img"
                      loading="lazy"
                    />
                  </div>

                  <div class="post-content">
                    <p class="text-gray-500">{{ post.date }}</p>
                    <h1
                      class="post-name text-xl text-black font-bold mt-0 mb-4"
                    >
                      {{ post.name }}
                    </h1>

                    <p
                      v-html="post.content.substr(0, 500) + '...'"
                      class="post-description text-xs text-justify text-gray-900 text-light mb-12"
                    ></p>
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'post-slug',
                          params: { slug: post.slug }
                        })
                      "
                      class="hidden post-button bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white mt-6 text-white px-5 py-3"
                      :aria-label="post.name"
                      >{{ $t('read_more') }}
                      <span class="hidden"> about {{ post.name }} </span>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Optional controls -->
          <div slot="pagination" class="mt-24 swiper-pagination"></div>
          <div
            slot="button-prev"
            class="hidden sm:block swiper-button-prev"
          ></div>
          <div
            slot="button-next"
            class="hidden sm:block swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  </no-ssr>
</template>

<script>
import postsJSON from '~/static/posts.json'

export default {
  data() {
    return {
      active: 1,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        autoHeight: true, // enable auto height
        spaceBetween: 0,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        allowTouchMove: false,
        slidesPerGroup: 1,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
            allowTouchMove: true
          }
        }
      }
    }
  },
  computed: {
    languagePrefix() {
      return this.$i18n.locale !== 'en' ? this.$i18n.locale + '/' : ''
    },
    lang() {
      return this.$i18n.locale
    },
    posts() {
      return postsJSON.filter(post => {
        return post.lang === this.lang
      })
    }
  }
}
</script>

<style scoped>
.post-name,
.post-content {
  /* outline: 1px solid red; */
}
.post-name {
  line-height: 1.1;
}
.post-container {
  transition: 0.3s;
  position: relative;
  /* outline: 2px solid green; */
  min-height: 400px;
  opacity: 0.75;
}
.img-wrapper {
  display: none;
}
.post-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide {
  width: 100%;
}
@screen lg {
  .swiper-slide {
    width: 20%;
  }
  .img-wrapper {
    align-self: center;
    width: 50%;
    padding: 0 10px;
    height: 350px;
  }
  .swiper-slide-active {
    width: 59%;
  }
  .swiper-slide-active .post-name {
    @apply text-2xl;
  }
  .swiper-slide-active .post-description {
    @apply text-sm;
  }
  .swiper-slide-active .post-content {
    width: 50%;
  }
}
.post-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.swiper-slide-active .post-container {
  /* outline: 3px solid blue; */
  /* opacity: 1; */
  opacity: 0.9;
  @apply shadow-lg;
}
.swiper-slide-active .post-button {
  display: block;
}
.post-content {
  /* padding: 11px; */
  padding: 10px;
}
.swiper-slide-active .post-content {
}
.swiper-slide-active .img-wrapper {
  display: block;
}
</style>
