var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperOption),expression:"swiperOption",arg:"mySwiper"}],staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('nuxt-link',{staticClass:"heroimg",attrs:{"to":_vm.localePath({
                name: 'about',
                params: { slug: '' }
              })}},[_c('img',{staticClass:"self-center",attrs:{"src":_vm.$t('hero_img2'),"alt":"hero image","title":"hero image"}})])],1),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('nuxt-link',{staticClass:"heroimg",attrs:{"to":_vm.localePath({
                name: 'solution-contract-logistics-slug',
                params: { slug: 'automated-sortation-system' }
              })}},[_c('img',{staticClass:"self-center",attrs:{"src":_vm.$t('hero_img3'),"alt":"hero image","title":"hero image"}})])],1),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('nuxt-link',{staticClass:"heroimg",attrs:{"to":_vm.localePath({
                name: 'solution-contract-logistics-slug',
                params: { slug: 'autostore' }
              })}},[_c('img',{staticClass:"self-center",attrs:{"src":_vm.$t('hero_img'),"alt":"hero image"}})])],1),_vm._v(" "),_c('div',{staticClass:"swiper-slide"},[_c('nuxt-link',{staticClass:"heroimg",attrs:{"to":_vm.localePath({
                name: 'career-programletnjeprakse-stipendiranjestudenata',
                params: { slug: '' }
              })}},[_c('img',{staticClass:"self-center",attrs:{"src":_vm.$t('hero_img4'),"alt":"hero image"}})])],1)]),_vm._v(" "),_c('div',{staticClass:"mt-24 swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_vm._v(" "),_c('div',{staticClass:"hidden sm:block swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"hidden sm:block swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})])],_vm._v(" "),_c('BrowseNetwork'),_vm._v(" "),_c('TrackerBanner'),_vm._v(" "),_c('Stats',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}]}),_vm._v(" "),_c('News'),_vm._v(" "),_c('Solutions',{attrs:{"home":true}}),_vm._v(" "),_c('section',{staticClass:"quote text-white text-center flex flex-col items-center justify-center px-4"},[_c('div',{staticClass:"container-sm mx-auto md:px-64"},[_c('p',{staticClass:"text-2xl mb-8"},[_vm._v(_vm._s(_vm.$t('home_banner_text')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('home_banner_footer')))])])]),_vm._v(" "),_c('SvetLogistike',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }