<template>
  <div class="container mx-auto my-6 text-center">
    <!-- <div class="lg:flex flex-wrap justify-between px-4 items-center">
      <div class="flex items-center">
        <img class="h-12" src="/img/cargowise-logo.png" />
        <div class="ml-2">
          <p class="text-4xl font-bold -mb-2">{{ $t('tracking') }}</p>
          <p class="text-gray-700 text-xl">Track your shipments here.</p>
        </div>
      </div>

      <div>
        <img src="/img/logo-web-tracker.gif" class="h-16" />
      </div> -->
    <nuxt-link
      to="/tracking"
      class="inline-flex justify-around items-center bg-blue-600 hover:bg-blue-700 px-24 text-center uppercase hover:text-white text-white py-3"
    >
      <v-icon class="icon h-4 w-4 mr-2" name="search"></v-icon>
      <span>{{ $t('tracking') }}</span>
    </nuxt-link>
    <!-- </div> -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="postcss" scoped></style>
