<template>
  <section class="svet-logistike px-4 container mx-auto py-3 md:py-12">
    <div class="container-md pt-2 md:pt-8">
      <p class="uppercase font-light text-grey-100 -mb-2">
        / {{ $t('career') }}
      </p>
      <h3 class="text-3xl text-black font-bold uppercase mt-0 mb-4">
        {{ $t('explore_logistics') }}
      </h3>
      <div class="components flex max-w-md flex-col mt-12">
        <div
          :class="{ active: choice == '1' }"
          @click="choice = 1"
          class="flex cursor-pointer flex-row"
        >
          <img src="/img/icons/our_values.webp" 
          alt="our_values image"
          loading="lazy"
          />
          <div class="content">
            <h4>{{ $t('our_traditions') }}</h4>
            <p v-if="choice == 1" class="text-gray-600 info pt-4 h-12">
              {{ $t('values_text') }}
            </p>
          </div>
        </div>
        <div
          :class="{ active: choice == '2' }"
          @click="choice = 2"
          class="flex cursor-pointer flex-row"
        >
          <img src="/img/icons/best_practice.webp" 
          alt="best_practice image"
          loading="lazy"
          />
          <div class="content">
            <h4>{{ $t('best_practices') }}</h4>
            <p v-if="choice == 2" class="text-gray-600 info pt-4 h-12">
              {{ $t('practices_text') }}
            </p>
          </div>
        </div>
        <div
          :class="{ active: choice == '3' }"
          @click="choice = 3"
          class="flex cursor-pointer flex-row mb-8"
        >
          <img src="/img/icons/vacancies.webp" 
          alt="vacancies image"
          loading="lazy"
          />
          <div class="content">
            <h4>{{ $t('vacancies') }}</h4>
            <p v-if="choice == 3" class="text-gray-600 info pt-4 h-12">
              {{ $t('vacancies_text') }}
            </p>
          </div>
        </div>
      </div>
      <nuxt-link
        :to="localePath('career')"
        class="bg-blue-600 hover:bg-blue-700 px-8 lg:ml-20 uppercase hover:text-white mt-24   text-white px-5 py-3"
        >{{ $t('career') }}</nuxt-link
      >
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      choice: 1
    }
  }
}
</script>

<style scoped>
.svet-logistike {
  background: url('/img/svetlogistike.webp');
  min-height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}
@screen md {
  .svet-logistike {
    min-height: 60vh;
  }
}
img {
  height: 52px;
  width: 52px;
  opacity: 0.25;
}

.components .content {
  border-left: 2px solid theme('colors.gray.400');
  display: flex;
  flex-direction: column;
  @apply pl-4;
  @apply ml-4;
}
.components .active h4 {
  @apply text-blue-600;
  transition: 0.4s;
}
.components .active .content {
  border-color: theme('colors.blue.600');
  transition: 0.7s;
}
.components .active img {
  opacity: 0.99;
  /* opacity: 1; */
  transition: 0.7s;
}
.components .content {
  padding-bottom: 30px;
}
</style>
