<template>
  <section
    class="stats flex-col flex-wrap md:flex-row flex px-4 container mx-auto my-16 w-full justify-around"
  >
    <div class="text-center md:text-left md:w-1/4">
      <img src="/img/employees_icon.png" alt />
      <number
        ref="number1"
        v-view.once="playAnimation1"
        :from="0"
        :to="employees"
        :duration="2"
        class="text-3xl  text-blue-700 count"
        animation-paused
        easing="Power.easeIn"
      />
      <p>{{ $t('number_of_employees') }}</p>
    </div>
    <div class="md:w-1/4">
      <img src="/img/revenue_icon.png" alt />
      <number
        ref="number2"
        v-view.once="playAnimation2"
        :from="0"
        :to="revenue"
        :duration="2"
        class="text-3xl text-blue-700 count"
        animation-paused
        easing="Power.easeIn"
      />
      <p>{{ $t('annual_revenue') }}</p>
    </div>
    <div class="md:w-1/4">
      <img src="/img/objects_icon.png" alt />
      <number
        ref="number3"
        v-view.once="playAnimation3"
        :from="0"
        :to="object_size"
        :duration="2"
        class="text-3xl text-blue-700 count"
        animation-paused
        easing="Power.easeIn"
      />
      <p>{{ $t('object_size') }}</p>
    </div>
    <div class="md:w-1/4">
      <img src="/img/offices_icon.png " alt />
      <number
        ref="number4"
        v-view.once="playAnimation4"
        :from="0"
        :to="number_of_offices"
        :duration="2"
        class="text-3xl text-blue-700 count"
        animation-paused
        easing="Power.easeIn"
      />
      <p>{{ $t('number_of_offices') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      employees: 3700,
      revenue: 550,
      object_size: 520000,
      number_of_offices: 82
    }
  },
  methods: {
    onReady: function(instance, CountUp) {
      const that = this
      instance.update(that.endVal + 100)
    },
    playAnimation1() {
      this.$refs.number1.play()
    },
    playAnimation2() {
      this.$refs.number2.play()
    },
    playAnimation3() {
      this.$refs.number3.play()
    },
    playAnimation4() {
      this.$refs.number4.play()
    }
  }
}
</script>

<style scoped>
.stats div {
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}
@screen md {
  .stats div {
    flex-direction: row;
  }
}

.stats img {
  height: 36px;
  width: auto;
}
p {
  @apply uppercase;
  @apply leading-none;
  @apply my-2;
}
p + p {
  font-size: 1em;
}
.count {
  @apply mx-1;
}
</style>
