var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solutions px-4 container mx-auto"},[_vm._l((_vm.solutions),function(solution){return _c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],key:solution.slug,staticClass:"solution items-center flex-col md:flex-row"},[_c('img',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"self-center",attrs:{"src":'/img/' + solution.slug + solution.image_extension,"alt":solution.name[_vm.lang] + ' image',"loading":"lazy"}}),_vm._v(" "),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}]},[(_vm.home)?_c('p',{staticClass:"uppercase font-light -mb-1"},[_vm._v("\n        / "+_vm._s(_vm.$t('OUR_SOLUTIONS'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"text-3xl text-black font-bold mt-0 mb-4"},[_vm._v("\n        "+_vm._s(solution.name[_vm.lang])+" \n      ")]),_vm._v(" "),_c('p',{staticClass:"text-gray-900 text-light mb-12 text-sm"},[_vm._v("\n        "+_vm._s(solution.description[_vm.lang])+"\n      ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white mt-6 text-white px-5 py-3",attrs:{"to":_vm.localePath({
            name: 'solution-slug',
            params: { slug: solution.slug }
          })}},[_vm._v(_vm._s(_vm.$t('read_more'))+"\n        ")])],1)])}),_vm._v(" "),_c('div',{staticClass:"solution items-center flex-col md:flex-row"},[_c('img',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"self-center",attrs:{"src":'/img/' + 'automotive-logistics' + '.webp',"alt":"automotive-logistics image"}}),_vm._v(" "),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}]},[(_vm.home)?_c('p',{staticClass:"uppercase font-light -mb-1"},[_vm._v("\n        / "+_vm._s(_vm.$t('OUR_SOLUTIONS'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"text-3xl text-black font-bold mt-0 mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('automotive_logistics'))+" \n      ")]),_vm._v(" "),_c('p',{staticClass:"text-gray-900 text-light mb-12 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('automotive_logistics_description'))+"\n      ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"bg-blue-600 hover:bg-blue-700 px-12 uppercase hover:text-white mt-6 text-white px-5 py-3",attrs:{"to":_vm.localePath({
            name: 'solution-slug',
            params: { slug: 'automotive-logistics' }
          })}},[_vm._v(_vm._s(_vm.$t('read_more'))+"\n        "+_vm._s(_vm.$t('automotive_logistics'))+"\n        ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }